import React from 'react';
import { Link, graphql, useStaticQuery } from 'gatsby';
import GatsbyImage from 'gatsby-image';

import { Layout, SEO, Hero, ContactSection } from '../components';
import ProjectCarousel from '../components/ProjectCarousel';
import { useGraphQL } from '../hooks';

function AboutPage() {
  const projects = useStaticQuery(graphql`
    {
      project1: file(name: { eq: "project-1" }) {
        childImageSharp {
          fluid(maxWidth: 1440, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp_tracedSVG
          }
        }
      }
      project2: file(name: { eq: "project-2" }) {
        childImageSharp {
          fluid(maxWidth: 1440, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp_tracedSVG
          }
        }
      }
      project3: file(name: { eq: "project-3" }) {
        childImageSharp {
          fluid(maxWidth: 1440, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp_tracedSVG
          }
        }
      }
      project4: file(name: { eq: "project-4" }) {
        childImageSharp {
          fluid(maxWidth: 1440, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp_tracedSVG
          }
        }
      }
      project5: file(name: { eq: "project-5" }) {
        childImageSharp {
          fluid(maxWidth: 1440, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp_tracedSVG
          }
        }
      }
      project6: file(name: { eq: "project-6" }) {
        childImageSharp {
          fluid(maxWidth: 1440, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp_tracedSVG
          }
        }
      }
    }
  `);

  const projArray = [
    {
      img: projects.project1,
      description:
        'Power & NBN for multiple subdivision from Mudgee to Broken Hill up to Tweed Heads & south to Forster.',
    },
    {
      img: projects.project2,
      description: 'Installation of new overhead power pole in Guyra NSW.',
    },
    {
      img: projects.project3,
      description:
        'Installation of new Streetlights at the Moore Creek Road roundabout.',
    },
    {
      img: projects.project4,
      description:
        'Installation of 4 way ground service column see-saw baseplate streetlight Cnr of Peel St & Marius St Tamworth NSW.',
    },
    {
      img: projects.project5,
      description:
        'Installation of new street lighting for Hastings River Drive Port Macquarie NSW.',
    },
    {
      img: projects.project6,
      description: 'Installation of new under ground cable.',
    },
  ];
  return (
    <Layout>
      <SEO title="About Us" />
      <HeroSection />
      <WhatWeAreAbout />
      <OurFocus />
      <ProjectCarousel allFile={projArray} />
      <ContactSection bgColorClass="bg-gray-200" />
    </Layout>
  );
}

function HeroSection() {
  const { aboutHero } = useGraphQL();

  return (
    <Hero image={aboutHero.childImageSharp.fluid}>
      <div className="w-full space-y-4 text-center">
        <h1 className="text-white heading-1">About us</h1>
        <Link to="/contact/" className="text-white button">
          Get in touch
        </Link>
      </div>
    </Hero>
  );
}

function WhatWeAreAbout() {
  const { whatWeAreAboutHero } = useGraphQL();

  return (
    <article className="relative bg-white">
      <div className="w-full px-4 py-8 mx-auto md:py-16 max-w-7xl sm:px-6 lg:px-8">
        <div className="grid justify-center gap-8 sm:gap-14 md:grid-cols-2">
          <div className="w-full mx-auto max-w-prose">
            <div className="relative h-0 aspect-ratio-square">
              <div className="absolute inset-0 flex bg-sky-blue">
                <GatsbyImage
                  fluid={whatWeAreAboutHero.childImageSharp.fluid}
                  className="flex-1"
                />
              </div>
            </div>
          </div>
          <div className="flex items-center md:py-12">
            <div>
              <h2 className="text-2xl font-bold tracking-wider text-center uppercase text-light-blue md:text-left">
                What we are about
              </h2>

              <p className="mt-2">
                Midstate Power Pty Ltd originated in Mudgee NSW in 2014 as a
                Level 1 Accredited Service Provider, before moving our
                operations to Tamworth NSW due to the high demand. This has
                allowed Midstate Power to service greater regional NSW including
                the far North Coast where we now have a depot as well.
              </p>
            </div>
          </div>
        </div>
      </div>
    </article>
  );
}

function OurFocus() {
  const { ourFocusHero } = useGraphQL();

  return (
    <article className="relative bg-light-blue">
      <div className="w-full px-4 py-8 mx-auto md:py-16 max-w-7xl sm:px-6 lg:px-8">
        <div className="flex flex-col justify-center space-y-8 md:space-y-0 md:flex-row-reverse">
          <div className="w-full mx-auto max-w-prose md:w-1/2 md:pl-7">
            <div className="relative h-0 aspect-ratio-square">
              <div className="absolute inset-0 flex bg-sky-blue">
                <GatsbyImage
                  fluid={ourFocusHero.childImageSharp.fluid}
                  className="flex-1"
                />
              </div>
            </div>
          </div>
          <div className="flex items-center w-full md:py-12 md:w-1/2 md:pr-7">
            <div>
              <h2 className="text-2xl font-bold tracking-wider text-center text-white uppercase md:text-left">
                Our Focus
              </h2>

              <p className="mt-2 text-white">
                Our main focus was &amp; still is to supply a very high standard
                of service for the local community including local councils and
                farmers in the region. Midstate Power Pty Ltd pride themselves
                on repeat business, this is normally because of the ability to
                have projects completed &amp; accepted by Essential Energy with
                the minimum of fuss, also as well as offering competitive market
                rates.
              </p>
            </div>
          </div>
        </div>
      </div>
    </article>
  );
}

export default AboutPage;
